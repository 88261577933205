import { graphql } from 'gatsby';
import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { useWindowSize } from 'react-use';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ArticleMenu from 'components/organisms/ArticleMenu';
import ContactHero from 'components/organisms/ContactHero';
import CtaSection from 'components/organisms/CtaSection';
import { md2react } from 'helpers/parser'

const PrivacyPageContent = ({ hero, sections, cta }) => {

  let sectionsWithId = sections.map((el, index) => ({
    ...el,
    id: `${snakeCase(el.label)}_${index}`
  }));

  const { width } = useWindowSize();
  const headerOffset = width < 768 ? 64 : 96;

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (!el) {
      return;
    }
    window.scrollTo({ top: el.offsetTop - headerOffset, behavior: 'smooth' });
  };

  return (
    <>
      <ContactHero
        title={hero?.title}
        background="bg-violet-alt"
        inverted
      />
      <div className="container max-w-screen-xl mx-auto my-12 md:my-20 flex flex-col md:flex-row justify-between px-6 lg:px-md">
        <div className="md:hidden mb-8">
          <select
            onChange={(e) => {
              if (!window) {
                return;
              }

              scrollTo(e.target.value);
            }}
            className="w-full border border-grey-400 px-4 py-3 font-semibold outline-none"
          >
            {sectionsWithId.map((section) => (
              <option key={section.id} value={section.id}>
                {section.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-16 md:mb-0 hidden md:block">
          <ArticleMenu sections={sectionsWithId} />
        </div>
        <div className="md:ml-8 w-article max-w-full">
          {sectionsWithId.map((section) => (
            <div key={section.id} id={section.id} className="prose mb-8">{md2react(section?.content)}</div>
          ))}
        </div>
      </div>
      <CtaSection headline={cta?.headline} />
    </>
  );
};

export const PrivacyPreview = ({ entry }) => {
  return <PrivacyPageContent {...entry.get('data').toJS()} />;
};

const PrivacyPage = ({
   data: {
     markdownRemark: { frontmatter },
   },
 }) => {
  const { seo } = frontmatter;

  return (
    <Layout headerBackground="bg-violet-alt" headerInverted>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <PrivacyPageContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PrivacyPageQuery2 {
    markdownRemark(fileAbsolutePath: { regex: "/pages/privacy/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
        }
        sections {
          label
          content
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default PrivacyPage;
